<template>
   <div id="stickerdetail">
    <LottieBackground
      :preferredCategoryID="skillCategory.SkillCategoryID"
      :preferredNo="3"
      :preferredSkill="skill.SkillID"
    />

    <div class="container py-4 py-lg-5">
      <div class="row">
        <div class="col">
          <b-button variant="primary" :to="{ name: 'stickerbook', params: { student_id: $route.params.student_id, group_id: $route.params.group_id } }" class="mb-2 mb-lg-3">
            <font-awesome-icon icon="angle-left" fixed-width/>
            {{ $t("context.back_to_stickers") }}
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col col-md-6">
          <div class="card">
            <div class="card-body">
              <h1 class="h2">{{ skillPassed ? decodeHTML($t('context.skill_passed', { skillName: skill.Name.toLowerCase() })) : decodeHTML($t('context.skill_not_passed', { skillName: skill.Name })) }}</h1>
              <!-- <p class="mb-0">(( message here ))</p> -->
              <div class="float-right">
                <TheMascotte
                  :success="skillPassed"
                  :preferredCategoryID="skillCategory.SkillCategoryID"
                  :preferredNo="3"
                  :preferredSkill="skill.SkillID"
                />
              </div>
            </div>
          </div>

          <div class="card stickerbook mt-5">
            <div class="card-header">
              <TheSticker
                class="sticker-wrap"
                :link="false"
                :image="skill.Image"
                :showName="false"
                :skill="skill"
                :celebrationPossible="true"
                @partyHornClicked="startCelebration"
              />
              <div class="text">
                <h5 class="mb-0" v-if="skill && skill.Name">
                  {{ decodeHTML($t('context.skill_tip_for', { skillName: skill.Name.toLowerCase() })) }}
                </h5>
              </div>
            </div>
            <div class="card-body">
              <div v-if="skillRecord.Description" v-html="skillRecord.Description"></div>
              <div v-else>
                <p class="mb-0">{{ $t('context.no_tips_available') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card achievement" :class="{ 'success': skillPassed }" ref="achievement">
            <div class="card-body text-center" v-if="skill">
              <TheSticker
                :skill="skill"
                :image="skill.Image"
                :link="false"
                :index="1"
                :showName="false"
                :showHorn="false"
                :locked="!skillPassed"
                v-show="!hideAchievementButton"
              />

              <div class="sticker animate__animated animate__bounceIn no-offset" v-show="hideAchievementButton">
                <img :src="skill.Image" class="image completed" />
              </div>

              <h2 class="mb-3"><strong>{{ skillPassed ? decodeHTML($t('context.student_knows_skill', { name: student.Name, skill: skill.Name.toLowerCase() })) : decodeHTML($t('context.skill_not_completed', { skill: skill.Name })) }}</strong></h2>
              <p class="mb-0" v-if="skills.length > 1">
                {{ decodeHTML($t('context.student_knows_skills', { skillCount: skillsPassed, totalSkills: skills.length, skillName: skillCategory.Name })) }}
              </p>
              <p v-if="skillPassed && skillPassedDate"><strong>{{ $t('context.student_archived_skill', { date: skillPassedDate }) }}</strong></p>

              <button class="btn btn-primary mt-3" @click="createDownloadableImage" v-show="skillPassed && !hideAchievementButton">
                <font-awesome-icon icon="share-nodes" fixed-width/>
                {{ $t('context.create_downloadable_image') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheSticker from '@/components/TheSticker'
import TheMascotte from '@/components/TheMascotte'
import LottieBackground from '@/components/LottieBackground'
import skillsMixin from '@/util/skillsMixin'
import initialsMixin from '@/util/initialsMixin'
import { protectedApi } from '@/http'
import he from 'he'

export default {
  name: 'StickerDetail',

  mixins: [
    skillsMixin,
    initialsMixin
  ],

  components: {
    TheSticker,
    TheMascotte,
    LottieBackground
  },

  data () {
    return {
      skillRecord: {},
      celebrationDuration: 10000, // 10 seconds
      celebrating: false,
      achievementCanvas: null,
      hideAchievementButton: false
    }
  },

  computed: {
    skillPassedDate () {
      if (this.skillPassed) {
        let date = new Date(this.skill.ModifiedDate || this.skill.CreatedDate)

        return date.toLocaleString('nl-NL', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })
      }

      return false
    },

    celebratedSkill () {
      return this.$store.getters['persons/celebrationWatched'](this.selectedPersonID + '-' + this.skill.SkillID)
    }
  },

  created () {
    this.fetchSkill()
    this.checkCelebration()
  },

  methods: {
    fetchSkill () {
      protectedApi.get('/skills/' + this.$route.params.skill_id)
        .then(response => {
          this.skillRecord = response.data
        })
    },

    checkCelebration () {
      if (this.student.PersonID === this.selectedPersonID && !this.celebratedSkill && this.skillPassed) {
        this.startCelebration()
        this.$store.dispatch('persons/setCelebrationWatched', this.selectedPersonID + '-' + this.skill.SkillID)
      }
    },

    startCelebration () {
      if (!this.celebrating) {
        this.celebrating = true

        this.$confetti.update({
          defaultSize: 6
        })

        this.$confetti.start()

        setTimeout(() => {
          this.$confetti.stop()
          this.celebrating = false
        }, this.celebrationDuration)
      }
    },

    async createDownloadableImage () {
      const achievement = this.$refs.achievement
      this.hideAchievementButton = true

      const options = {
        type: 'dataURL'
      }

      const createImage = await this.$html2canvas(achievement, options)

      if (createImage) {
        this.achievementCanvas = createImage

        let a = document.createElement('a')
        a.href = createImage
        a.download = this.skill.Name + '.png'
        a.click()

        setTimeout(() => {
          this.hideAchievementButton = false
        }, 1000)
      }
    },
    decodeHTML (text) {
      return he.decode(text)
    }
  },

  watch: {
    '$route' () {
      if (this.$route.name === 'stickerdetail') {
        this.checkCelebration()
      }
    }
  }
}
</script>
